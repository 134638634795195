import Chess, { ChessInstance, PieceType, Square } from "chess.js";

export type Color = "w" | "b";
export type PieceWeights = Record<PieceType, number>;
export type RawPieceSquareTable = Record<PieceType, number[][]>;
export type PieceSquareTable = Record<PieceType, Record<Square, number>>;
export type PlayerPieceSquareTable = Record<Color, PieceSquareTable>;

// NB: weights and PSTs taken from Sunfish engine

export const pieceWeights: PieceWeights = {
  p: 100,
  n: 280,
  b: 320,
  r: 479,
  q: 929,
  k: 60000,
};

// Table of victims and attackers indexed by [victim][attacker]
export const mvvLvaTable: Record<PieceType, Record<PieceType, number>> = {
  k: { k: 0, q: 0, r: 0, b: 0, n: 0, p: 0 }, // victim K, attacker K, Q, R, B, N, P
  q: { k: 50, q: 51, r: 52, b: 53, n: 54, p: 55 }, // victim Q, attacker K, Q, R, B, N, P
  r: { k: 40, q: 41, r: 42, b: 43, n: 44, p: 45 }, // victim R, attacker K, Q, R, B, N, P
  b: { k: 30, q: 31, r: 32, b: 33, n: 34, p: 35 }, // victim B, attacker K, Q, R, B, N, P
  n: { k: 20, q: 21, r: 22, b: 23, n: 24, p: 25 }, // victim N, attacker K, Q, R, B, N, P
  p: { k: 10, q: 11, r: 12, b: 13, n: 14, p: 15 }, // victim P, attacker K, Q, R, B, N, P
};

const pstForWhite: RawPieceSquareTable = {
  p: [
    [100, 100, 100, 100, 105, 100, 100, 100],
    [78, 83, 86, 73, 102, 82, 85, 90],
    [7, 29, 21, 44, 40, 31, 44, 7],
    [-17, 16, -2, 15, 14, 0, 15, -13],
    [-26, 3, 10, 9, 6, 1, 0, -23],
    [-22, 9, 5, -11, -10, -2, 3, -19],
    [-31, 8, -7, -37, -36, -14, 3, -31],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  n: [
    [-66, -53, -75, -75, -10, -55, -58, -70],
    [-3, -6, 100, -36, 4, 62, -4, -14],
    [10, 67, 1, 74, 73, 27, 62, -2],
    [24, 24, 45, 37, 33, 41, 25, 17],
    [-1, 5, 31, 21, 22, 35, 2, 0],
    [-18, 10, 13, 22, 18, 15, 11, -14],
    [-23, -15, 2, 0, 2, 0, -23, -20],
    [-74, -23, -26, -24, -19, -35, -22, -69],
  ],
  b: [
    [-59, -78, -82, -76, -23, -107, -37, -50],
    [-11, 20, 35, -42, -39, 31, 2, -22],
    [-9, 39, -32, 41, 52, -10, 28, -14],
    [25, 17, 20, 34, 26, 25, 15, 10],
    [13, 10, 17, 23, 17, 16, 0, 7],
    [14, 25, 24, 15, 8, 25, 20, 15],
    [19, 20, 11, 6, 7, 6, 20, 16],
    [-7, 2, -15, -12, -14, -15, -10, -10],
  ],
  r: [
    [35, 29, 33, 4, 37, 33, 56, 50],
    [55, 29, 56, 67, 55, 62, 34, 60],
    [19, 35, 28, 33, 45, 27, 25, 15],
    [0, 5, 16, 13, 18, -4, -9, -6],
    [-28, -35, -16, -21, -13, -29, -46, -30],
    [-42, -28, -42, -25, -25, -35, -26, -46],
    [-53, -38, -31, -26, -29, -43, -44, -53],
    [-30, -24, -18, 5, -2, -18, -31, -32],
  ],
  q: [
    [6, 1, -8, -104, 69, 24, 88, 26],
    [14, 32, 60, -10, 20, 76, 57, 24],
    [-2, 43, 32, 60, 72, 63, 43, 2],
    [1, -16, 22, 17, 25, 20, -13, -6],
    [-14, -15, -2, -5, -1, -10, -20, -22],
    [-30, -6, -13, -11, -16, -11, -16, -27],
    [-36, -18, 0, -19, -15, -15, -21, -38],
    [-39, -30, -31, -13, -31, -36, -34, -42],
  ],
  k: [
    [4, 54, 47, -99, -99, 60, 83, -62],
    [-32, 10, 55, 56, 56, 55, 10, 3],
    [-62, 12, -57, 44, -67, 28, 37, -31],
    [-55, 50, 11, -4, -19, 13, 0, -49],
    [-55, -43, -52, -28, -51, -47, -8, -50],
    [-47, -42, -43, -79, -64, -32, -29, -32],
    [-4, 3, -14, -50, -57, -18, 13, 4],
    [17, 30, -3, -14, 6, -1, 40, 18],
  ],
};

const pstForBlack = {
  p: pstForWhite["p"].slice().reverse(),
  n: pstForWhite["n"].slice().reverse(),
  b: pstForWhite["b"].slice().reverse(),
  r: pstForWhite["r"].slice().reverse(),
  q: pstForWhite["q"].slice().reverse(),
  k: pstForWhite["k"].slice().reverse(),
};

function rawToPST(raw: RawPieceSquareTable): PieceSquareTable {
  const chess: ChessInstance = new (Chess as any)();

  const tableToRecord = (table: number[][]) =>
    chess.SQUARES.reduce((prev, square) => {
      const rank = 8 - parseInt(square[1]);
      const file = square.charCodeAt(0) - "a".charCodeAt(0);
      return { ...prev, [square]: table[rank][file] };
    }, {} as Record<Square, number>);

  return {
    p: tableToRecord(raw["p"]),
    n: tableToRecord(raw["n"]),
    b: tableToRecord(raw["b"]),
    r: tableToRecord(raw["r"]),
    q: tableToRecord(raw["q"]),
    k: tableToRecord(raw["k"]),
  };
}

export const pstForOpponent: PlayerPieceSquareTable = {
  w: rawToPST(pstForBlack),
  b: rawToPST(pstForWhite),
};
export const pstForSelf: PlayerPieceSquareTable = {
  w: rawToPST(pstForWhite),
  b: rawToPST(pstForBlack),
};
