import { ChessInstance, Move } from "chess.js";
import QuickLRU from "quick-lru";
import { evaluateBoard } from "./evaluation";
import { generateMoves } from "./moves";
import { hashPosition, oppositeColor } from "./utils";
import { Color, pieceWeights } from "./weights";

export type TranspositionTableFlag = "exact" | "lowerbound" | "upperbound";

export interface TranspositionTableEntry {
  depth: number;
  flag: TranspositionTableFlag;
  value: number;
  move: Move | null;
}

export class SearchContext {
  nodes: number;
  qnodes: number;
  cutoffs: number;
  killerMoves: (Move | undefined)[][];
  transpositionTable: QuickLRU<string, TranspositionTableEntry>;

  readonly maxKillerMoves = 2;

  constructor(prevContext?: SearchContext) {
    this.nodes = 0;
    this.qnodes = 0;
    this.cutoffs = 0;
    this.killerMoves = Array.from(Array(this.maxKillerMoves)).map(() => []);
    this.transpositionTable =
      prevContext?.transpositionTable ??
      new QuickLRU<string, TranspositionTableEntry>({
        maxSize: 10_000_000,
      });
  }

  storeKillerMove(currentMove: Move, depth: number): void {
    let firstKiller = this.killerMoves[0][depth];

    // Don't duplicate the first killer move
    if (firstKiller?.san !== currentMove.san) {
      // Shift all killer moves up one index
      for (let i = this.maxKillerMoves - 1; i > 0; i--) {
        this.killerMoves[i][depth] = this.killerMoves[i - 1][depth];
      }

      // Add the new killer move at the first index
      this.killerMoves[0][depth] = currentMove;
    }
  }
}

export function negamax(
  game: ChessInstance,
  depth: number,
  alpha: number,
  beta: number,
  score: number,
  color: Color,
  context: SearchContext
): [Move | null, number] {
  const alphaOrig = alpha;

  // Quiescence search (depth <= 0) doesn't care about depth
  depth = Math.max(depth, 0);

  const hashKey = hashPosition(game);
  let ttEntry = context.transpositionTable.get(hashKey);
  if (ttEntry && ttEntry.depth >= depth) {
    if (ttEntry.flag === "exact") {
      return [ttEntry.move, ttEntry.value];
    } else if (ttEntry.flag === "lowerbound") {
      alpha = Math.max(alpha, ttEntry.value);
    } else if (ttEntry.flag === "upperbound") {
      beta = Math.min(beta, ttEntry.value);
    }

    if (alpha >= beta) {
      return [ttEntry.move, ttEntry.value];
    }
  }

  const possibleMoves = generateMoves(game, depth, ttEntry, context);
  const possibleCaptures = possibleMoves.filter((move) => !!move?.captured);

  if (!possibleMoves) {
    if (game.in_checkmate()) {
      return [null, -pieceWeights["k"]];
    }
  }

  // Halt at max depth or when a terminal node is reached
  if (depth === 0 || !possibleMoves) {
    // Halt if a terminal node is reached or if the position is quiet
    if (!possibleMoves || !possibleCaptures) {
      return [null, score];
    }

    // If the position isn't quiet, run quiescence search (depth = 0)

    // Deal with standing pat for qsearch
    if (score >= beta) return [null, beta];

    // Delta pruning
    // TODO: pawn promotion if included in q-search
    const bigDelta = pieceWeights["q"];
    if (score < alpha - bigDelta) return [null, alpha];

    if (alpha < score) alpha = score;
  }

  let bestValue = Number.MIN_SAFE_INTEGER;
  let bestMove: Move | null = null;
  for (const currentMove of possibleMoves) {
    // Skip non-captures in qsearch
    if (depth === 0 && !currentMove?.captured) {
      continue;
    }

    context.nodes++;
    if (depth === 0) {
      context.qnodes++;
    }

    let value = 0;

    // Handle null moves
    if (currentMove === null) {
      continue;
      // TODO: null move generation is inefficient and must be fixed in chess.js
      // console.log("null move");
      // const nullGame = makeNullMove(game);
      // Negate value for negamax
      // value = -negamax(
      //   nullGame,
      //   depth - 1,
      //   -beta,
      //   -alpha,
      //   -score,
      //   oppositeColor(color),
      //   context
      // )[1];
    } else {
      game.move(currentMove);

      const newScore = evaluateBoard(game, currentMove, score, color);
      // Negate value for negamax
      value = -negamax(
        game,
        depth - 1,
        -beta,
        -alpha,
        -newScore,
        oppositeColor(color),
        context
      )[1];

      game.undo();
    }

    if (value > bestValue) {
      bestValue = value;
      bestMove = currentMove;
    }

    alpha = Math.max(alpha, value);
    if (alpha >= beta) {
      context.cutoffs++;
      if (currentMove) {
        context.storeKillerMove(currentMove, depth);
      }
      break; // Alpha-beta pruning
    }
  }

  // Update transition table entry
  let flag: TranspositionTableFlag;
  if (bestValue <= alphaOrig) {
    flag = "upperbound";
  } else if (bestValue >= beta) {
    flag = "lowerbound";
  } else {
    flag = "exact";
  }
  ttEntry = { value: bestValue, move: bestMove, depth, flag };
  context.transpositionTable.set(hashKey, ttEntry);

  // Return alpha instead of evaluation in qsearch
  return depth === 0 ? [bestMove, alpha] : [bestMove, bestValue];
}

export function iterativeDeepening(
  game: ChessInstance,
  depth: number,
  score: number,
  color: Color,
  context: SearchContext
): [Move | null, number] {
  let result: [Move | null, number] = [null, 0];
  for (let ply = 1; ply <= depth; ply++) {
    const label = `Iterative deepening (depth=${ply})`;
    console.time(label);
    result = negamax(
      game,
      ply,
      Number.MIN_SAFE_INTEGER,
      Number.MAX_SAFE_INTEGER,
      score,
      color,
      context
    );
    console.timeEnd(label);
    console.log({ ply, move: result[0]?.san, score: result[1] });
  }
  return result;
}
