import Chess, { ChessInstance, Move, Square } from "chess.js";
import { CSSProperties, useState } from "react";
import { Chessboard } from "react-chessboard";
import { useElementSize } from "usehooks-ts";
import { evaluateBoard } from "./engine/evaluation";
import { iterativeDeepening, SearchContext } from "./engine/search";
import { oppositeColor } from "./engine/utils";
import { Color } from "./engine/weights";

const moveHighlightColor = "rgba(155,199,0,0.41)";

const colorNames: Record<Color, string> = { w: "White", b: "Black" };

export default function Board() {
  const [searchContext, setSearchContext] = useState<SearchContext>(
    new SearchContext()
  );
  const [game, setGame] = useState<ChessInstance>(new (Chess as any)());
  const [evaluation, setEvaluation] = useState<number>(0);
  const [moveSquares, setMoveSquares] = useState<{
    [P in Square]?: CSSProperties;
  }>({});
  const [squareRef, { width }] = useElementSize();

  function safeGameMutate(modify: (game: ChessInstance) => void) {
    setGame((g) => {
      const update = { ...g };
      modify(update);
      return update;
    });

    // Highlight the last move
    const lastMove = game.history({ verbose: true }).at(-1);
    const newMoveSquares: typeof moveSquares = {};
    [lastMove?.from, lastMove?.to].forEach((square) => {
      if (square) {
        newMoveSquares[square] = { backgroundColor: moveHighlightColor };
      }
    });
    setMoveSquares(newMoveSquares);

    // Alert on game over
    if (game.game_over()) {
      let message = "ERROR: unknown game over condition";
      if (game.in_checkmate()) {
        message = `Checkmate — ${colorNames[oppositeColor(game.turn())]} wins!`;
      } else if (game.in_stalemate()) {
        message = "Draw by stalemate.";
      } else if (game.in_threefold_repetition()) {
        message = "Draw by threefold repetition.";
      } else if (game.insufficient_material()) {
        message = "Draw by insufficient material.";
      }
      // Alert must be delayed to allow move to render
      setTimeout(() => alert(message), 500);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function makeRandomMove() {
    const possibleMoves = game.moves();
    if (game.game_over() || game.in_draw() || possibleMoves.length === 0)
      return; // exit if the game is over
    const randomIndex = Math.floor(Math.random() * possibleMoves.length);
    safeGameMutate((game: ChessInstance) => {
      game.move(possibleMoves[randomIndex]);
    });
  }

  function getBestMove(
    game: ChessInstance,
    color: Color,
    currSum: number
  ): [Move | null, number] {
    const depth = 4;

    console.time("move");
    // Keep transposition table in use
    setSearchContext(new SearchContext(searchContext));
    const [bestMove, bestMoveValue] = iterativeDeepening(
      game,
      depth,
      currSum,
      color,
      searchContext!
    );
    console.timeEnd("move");
    console.log(
      `Searched ${searchContext.nodes} nodes (${searchContext.qnodes} Q-nodes) with ${searchContext.cutoffs} cutoffs and ${searchContext.transpositionTable.size} transposition table entries`
    );

    console.log({ bestMove, bestMoveValue });

    return [bestMove, bestMoveValue];
  }

  function makeBestMove() {
    const [bestMove, bestMoveValue] = getBestMove(
      game,
      game.turn(),
      evaluation
    );
    setEvaluation(bestMoveValue);
    safeGameMutate((game: ChessInstance) => {
      bestMove && game.move(bestMove);
    });
  }

  function onDrop(sourceSquare: Square, targetSquare: Square) {
    let move = null;
    const turn = game.turn();
    safeGameMutate((game: ChessInstance) => {
      move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // always promote to a queen for example simplicity
      });
    });
    if (move === null) return false; // illegal move
    setEvaluation(evaluateBoard(game, move, evaluation, turn));
    console.log({ move, evaluation });
    setTimeout(makeBestMove, 200);
    return true;
  }

  return (
    <div className="max-w-screen-sm flex flex-col mx-auto space-y-4">
      <div ref={squareRef} className="shadow-lg rounded-lg">
        <Chessboard
          boardWidth={width}
          customBoardStyle={{ borderRadius: ".5rem" }}
          customSquareStyles={{
            ...moveSquares,
          }}
          position={game.fen()}
          onPieceDrop={onDrop}
        />
      </div>
      <div className="card">
        <p className="break-words">
          <strong>FEN:</strong>&nbsp;<code>{game.fen()}</code>
        </p>
      </div>
      {game.history().length > 0 && (
        <div className="card">
          <p>
            {game.history().map((move, plyNumber) => {
              const isWhite = plyNumber % 2 === 0;
              const moveNumber = `${Math.floor(plyNumber / 2) + 1}.`;
              return (
                <span key={plyNumber}>
                  <strong>{isWhite && moveNumber}</strong> {move}{" "}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </div>
  );
}
