import { ChessInstance, Move } from "chess.js";
import { SearchContext, TranspositionTableEntry } from "./search";
import { mvvLvaTable } from "./weights";

const MVV_LVA_OFFSET = Number.MAX_SAFE_INTEGER - 256;
const KILLER_VALUE = 10;
const TTMOVE_SORT_VALUE = 60;

interface ScoredMove extends Move {
  score: number;
}

function canPlayNullMove(game: ChessInstance): boolean {
  const board = game.board();
  for (const row of board) {
    for (const piece of row) {
      // Zugzwang risk is low if major/minor pieces are on the board
      // console.log(piece);
      if (piece && ["q", "r", "b", "n"].includes(piece?.type)) {
        return true;
      }
    }
  }
  return false;
}

export function generateMoves(
  game: ChessInstance,
  depth: number,
  ttEntry: TranspositionTableEntry | undefined,
  context: SearchContext
): (Move | null)[] {
  // Score moves by potential
  const moves = scoreMoves(
    game.moves({ verbose: true }),
    depth,
    ttEntry,
    context
  );
  // Randomize moves so the same move isn't picked every time when tied
  // moves.sort(() => Math.random() - 0.5);
  // Sort by highest score first
  moves.sort((a, b) => b.score - a.score);

  if (depth > 0 && canPlayNullMove(game)) {
    return [null, ...moves];
  }

  return moves;
}

export function scoreMoves(
  moves: Move[],
  depth: number,
  ttEntry: TranspositionTableEntry | undefined,
  context: SearchContext
): ScoredMove[] {
  return moves.map((move) => {
    let score = 0;
    if (move.san === ttEntry?.move?.san) {
      score = MVV_LVA_OFFSET + TTMOVE_SORT_VALUE;
    } else if (move.captured) {
      score = mvvLvaTable[move.captured][move.piece];
    } else {
      for (let i = 0; i < context.maxKillerMoves; i++) {
        const killer = context.killerMoves[i][depth];
        if (move.san === killer?.san) {
          score = MVV_LVA_OFFSET - (i + 1) * KILLER_VALUE;
          break;
        }
      }
    }
    return { ...move, score };
  });
}
