import Chess, { ChessInstance } from "chess.js";
import { Color } from "./weights";

export function oppositeColor(color: Color): Color {
  return color === "w" ? "b" : "w";
}

export function hashPosition(game: ChessInstance): string {
  const fen = game.fen();
  const clockIndex = fen.lastIndexOf(" ", fen.lastIndexOf(" ") - 1);
  // return hash(fen.slice(0, clockIndex));
  return fen.slice(0, clockIndex);
}

export function makeNullMove(game: ChessInstance): ChessInstance {
  // Parse FEN
  let [fen, color, castling, epSquare, halfmoves, fullmoves] = game
    .fen()
    .split(" ");

  // Increment game clocks
  halfmoves = (parseInt(halfmoves) + 1).toString();
  fullmoves = (parseInt(fullmoves) + (color === "b" ? 1 : 0)).toString();

  // Switch active color
  color = oppositeColor(color as Color);

  // Remove en passant square
  epSquare = "-";

  // Return new game instance after null move
  return new (Chess as any)(
    [fen, color, castling, epSquare, halfmoves, fullmoves].join(" ")
  );
}
