import { ChessInstance, Move, Square } from "chess.js";
import { Color, pieceWeights, pstForOpponent, pstForSelf } from "./weights";

const enPassantCaptureSquares: { [P in Square]?: Square } = {
  a6: "a5",
  b6: "b5",
  c6: "c5",
  d6: "d5",
  e6: "e5",
  f6: "f5",
  g6: "g5",
  h6: "h5",
  a3: "a4",
  b3: "b4",
  c3: "c4",
  d3: "d4",
  e3: "e4",
  f3: "f4",
  g3: "g4",
  h3: "h4",
};

const castlingRookFrom: Record<Color, Record<"k" | "q", Square>> = {
  w: { k: "h1", q: "a1" },
  b: { k: "h8", q: "a8" },
};

const castlingRookTo: Record<Color, Record<"k" | "q", Square>> = {
  w: { k: "f1", q: "d1" },
  b: { k: "f8", q: "d8" },
};

export function evaluateBoard(
  game: ChessInstance,
  move: Move,
  prevScore: number,
  color: Color
) {
  // TODO: Change endgame behavior for kings

  if (game.in_checkmate()) {
    // Opponent has been checkmated (good for us)
    if (move.color === color) {
      return pieceWeights["k"];
    }
    // We have been checkmated (bad for us)
    else {
      return -pieceWeights["k"];
    }
  }

  // The game is drawn (neither good nor bad)
  if (game.in_draw() || game.in_threefold_repetition() || game.in_stalemate()) {
    return 0;
  }

  if (game.in_check()) {
    // Opponent is in check (good for us)
    if (move.color === color) {
      prevScore += 50;
    }
    // We are in check (bad for us)
    else {
      prevScore -= 50;
    }
  }

  if (move.captured) {
    // En passant captures remove a piece from a different square, not the destination
    let captureSquare = move.to;
    if (move.flags.includes(game.FLAGS.EP_CAPTURE)) {
      captureSquare = enPassantCaptureSquares[move.to]!;
    }

    // Opponent piece was captured (good for us)
    if (move.color === color) {
      prevScore +=
        pieceWeights[move.captured] +
        pstForOpponent[move.color][move.captured][captureSquare];
    }
    // Our piece was captured (bad for us)
    else {
      prevScore -=
        pieceWeights[move.captured] +
        pstForSelf[move.color][move.captured][captureSquare];
    }
  }

  if (move.flags.includes("p")) {
    // NOTE: promote to queen for simplicity
    move.promotion = "q";

    // Our piece was promoted (good for us)
    if (move.color === color) {
      prevScore -=
        pieceWeights[move.piece] +
        pstForSelf[move.color][move.piece][move.from];
      prevScore +=
        pieceWeights[move.promotion] +
        pstForSelf[move.color][move.promotion][move.to];
    }
    // Opponent piece was promoted (bad for us)
    else {
      prevScore +=
        pieceWeights[move.piece] +
        pstForOpponent[move.color][move.piece][move.from];
      prevScore -=
        pieceWeights[move.promotion] +
        pstForOpponent[move.color][move.promotion][move.to];
    }
  } else {
    // The moved piece still exists on the updated board, so we only need to update the position value
    if (move.color !== color) {
      prevScore += pstForOpponent[move.color][move.piece][move.from];
      prevScore -= pstForOpponent[move.color][move.piece][move.to];
    } else {
      prevScore -= pstForSelf[move.color][move.piece][move.from];
      prevScore += pstForSelf[move.color][move.piece][move.to];
    }
  }

  // Update rook positions when castling
  const kingsideCastle = move.flags.includes(game.FLAGS.KSIDE_CASTLE);
  const queensideCastle = move.flags.includes(game.FLAGS.QSIDE_CASTLE);
  if (kingsideCastle || queensideCastle) {
    const castlingFlag = kingsideCastle ? "k" : "q";

    // Account for rook moves (king move is already covered)
    const from = castlingRookFrom[move.color][castlingFlag];
    const to = castlingRookTo[move.color][castlingFlag];

    if (move.color !== color) {
      // Opponent has castled
      prevScore += pstForOpponent[move.color]["r"][from];
      prevScore -= pstForOpponent[move.color]["r"][to];
    } else {
      // We have castled
      prevScore -= pstForSelf[move.color]["r"][from];
      prevScore += pstForSelf[move.color]["r"][to];
    }
  }

  return prevScore;
}
